
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import { Actions, Getters, Mutations } from '@/store/enums/AgencyEnums';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';

import AgencyCreateModal from '@/components/agency/AgencyCreateModal.vue';
import AgencyUpdateModal from '@/components/agency/AgencyUpdateModal.vue';
import { Business } from '@/models/BusinessModel';

import useBreakpoints from 'vue-next-breakpoints';
import toasts from '@/utils/toasts';
import Chip from '@/components/Chip.vue';

export default defineComponent({
  name: 'agency-table',
  components: {
    Card,
    DataTable,
    AgencyCreateModal,
    AgencyUpdateModal,
    Chip,
  },
  data: () => {
    return {
      checkedCustomers: [],
      loading: false,
      table: {
        total: 0,
        loading: false,
        items: [],
        search: '',
      },
      debounceGetAgencies: Function(),
      showCreateModal: false,
      showUpdateModal: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      agencies: Getters.GET_AGENCIES,
      pagination: Getters.GET_PAGINATION,
      actionErrors: Getters.GET_ACTION_ERRORS,
    }),
    agencyText() {
      return this.table.total > 1 ? 'partners' : 'partner';
    },
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        {
          name: 'Name',
          key: 'business_name',
          sortable: true,
        },
        {
          name: 'Discount',
          key: 'discount',
          sortable: true,
        },
        {
          name: 'Description',
          key: 'description',
          sortable: true,
          hidden: this.isMobile,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
          hidden: this.isMobile,
        },
        {
          name: 'Action',
          key: 'action',
          sortable: true,
          align: 'center',
          class: 'max-w-100px',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      allData: Actions.FETCH_AGENCIES,
      fetchData: Actions.FETCH_AGENCY,
      updateData: Actions.UPDATE_AGENCY,
      deleteData: Actions.DELETE_AGENCY,
    }),
    ...mapMutations({
      setAgency: Mutations.SET_AGENCY,
    }),
    formatList() {
      return this.agencies.map((item) => {
        return {
          id: item.id,
          business_name: item.business_name,
          business_website: item.business_website,
          business_phone_number: item.business_phone_number,
          subtitle: item.subtitle,
          description: item.description,
          business_logo: item.business_logo,
          business_logo_src: item.business_logo_src,
          discount: item.discount,
          address_line: item.address_line,
          city: item.city,
          state: item.state,
          post_code: item.post_code,
          country: item.country,
          status: item.status == 'active' ? 'active' : 'inactive',
        };
      });
    },
    loadData() {
      this.loading = true;
      this.table.loading = true;
      this.allData()
        .then(() => {
          this.table.items = this.formatList();
          this.table.total = this.pagination.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => (this.table.loading = false));
    },
    getStatus(agency: Business) {
      if (agency?.status === 'active') {
        return { type: 'success', text: 'Active', value: true, textStatus: '' };
      }

      return { type: 'warning', text: 'Inactive', value: false };
    },

    viewDetail(agency: Business) {
      this.setAgency(agency);

      return this.$router.push({
        name: 'view-partner',
        params: { id: agency.id },
      });
    },

    onSearch(search) {
      this.table.search = search;
    },

    handleAgencyCreated() {
      this.loadData();
    },

    handleAgencyUpdated() {
      this.loadData();
    },

    editDetail(agency: Business) {
      this.setAgency(agency);
      this.showUpdateModal = true;
    },

    confirmDelete(row: Business) {
      return toasts.confirm(
        `Are you sure you want to delete this partner? You can't undo this action...`,
        (response) => {
          if (response.isConfirmed) {
            this.handleDeleteData(row);
          }
        }
      );
    },

    handleDeleteData(row: Business) {
      this.deleteData(row.id)
        .then(() => {
          toasts.success('Partner Successfully Deleted');

          this.loadData();
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.actionErrors;

          toasts.error(errors, message);
        });
    },

    handleFormSubimitted() {
      this.showCreateModal = false;
      this.showUpdateModal = false;
      this.loadData();
    },

    toggleNew() {
      this.showCreateModal = true;
    },

    handleFormCancelled() {
      if (this.showUpdateModal) {
        this.setAgency(null);
      }

      this.showCreateModal = false;
      this.showUpdateModal = false;
    },
  },
});


import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { Form } from 'vee-validate';
import FormField from '@/components/forms/FormField.vue';
import AddressForm from '@/components/forms/AddressForm.vue';

import toasts from '@/utils/toasts';
import mask from '@/utils/mask';

import { Actions, Getters } from '@/store/enums/AgencyEnums';

import { Agency } from '@/schemas/business.schemas';
import { Business } from '@/models/BusinessModel';

const initialValues = {
  business_name: null,
  business_website: null,
  business_phone_number: null,
  subtitle: null,
  discount: '',
  description: '',
  business_logo: null,
  state: '',
  country: 'AU',
} as unknown as Business;

export default defineComponent({
  name: 'agency-create-form',
  emits: ['dataSubmitted', 'cancelForm'],
  props: {
    prefix: {
      type: String,
      default: '',
    },
  },
  components: {
    Form,
    FormField,
    AddressForm,
  },
  data: () => ({
    loading: false,
    isLoad: false,
    form: {} as typeof Form,
    formData: initialValues,
  }),
  async mounted() {
    this.form = this.$refs.createAgencyForm as typeof Form;
  },
  computed: {
    ...mapGetters({
      agency: Getters.GET_AGENCY,
      actionErrors: Getters.GET_ACTION_ERRORS,
    }),

    formSchema() {
      return Agency;
    },

    phoneMasking() {
      return mask.phone.aus;
    },
  },
  methods: {
    ...mapActions({
      createAgency: Actions.CREATE_AGENCY,
    }),

    handleCancel() {
      this.resetForm();

      this.$emit('cancelForm');
    },

    resetForm() {
      this.form.resetForm();

      // Reset the form
      this.formData = initialValues;
    },
    async submitForm(values) {
      if (this.loading) return;

      this.loading = true;

      this.formData = values;
      this.formData.status = 'active';

      return this.createAgency(this.formData)
        .then(() => {
          this.loading = false;
          this.resetForm();

          this.$emit('dataSubmitted');
        })
        .catch(() => {
          this.loading = false;
          if (this.actionErrors) {
            const { errors, message } = this.actionErrors;

            // Alert when creation request failed
            toasts.error(errors, message);
          }
        });
    },
    handleImageChange(value) {
      this.setFieldValue('business_logo', value);
    },
    handleStateChange(value) {
      this.setFieldValue('state', value);
    },
    handleStateBlur() {
      this.validateFormField('state');
    },
    handleCountryChange(value) {
      this.setFieldValue('country', value);
    },
    handleCountryBlur() {
      this.validateFormField('country');
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    addPrefix(name, prefix = '') {
      return prefix ? `${prefix}_${name}` : name;
    },
  },
});
